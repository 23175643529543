import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Fade, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    position: 'absolute',
  },
}));

function ButtonProgress({ isLoading, onClick, type = 'button', children }) {
  const classes = useStyles();

  return (
    <Button
      type={type}
      fullWidth
      variant="contained"
      color="primary"
      size="large"
      className={classes.button}
      disabled={isLoading}
      {...(onClick && { onClick })}
    >
      {children}
      <Fade in={isLoading} unmountOnExit>
        <CircularProgress
          size="1.5em"
          color="primary"
          className={classes.progress}
        />
      </Fade>
    </Button>
  );
}

ButtonProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  children: PropTypes.node.isRequired,
};

export default ButtonProgress;
