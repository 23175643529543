import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function StatusMessage({ children, color = 'inherit' }) {
  return children ? <Typography color={color}>{children}</Typography> : null;
}

StatusMessage.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['inherit', 'primary', 'error']),
};

export default StatusMessage;
