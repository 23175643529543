import React, { useState, useEffect } from 'react';

import { withFirebase } from '../Firebase';
import AuthUserContext from './context';

const withAuthentication = Component => {
  function WithAuthentication({ firebase, ...rest }) {
    const [authUser, setAuthUser] = useState(false);

    useEffect(() => {
      const unsubscribe = firebase.onAuthUserListener(
        user => {
          setAuthUser(user);
        },
        () => {
          setAuthUser(null);
        }
      );
      return () => {
        unsubscribe();
      };
    }, [firebase]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...rest} />
      </AuthUserContext.Provider>
    );
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
