import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function LinearIndeterminate({ color = 'primary' }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color={color} />
    </div>
  );
}

LinearIndeterminate.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
};

export default LinearIndeterminate;
