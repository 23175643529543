import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import PrimaryLayout from './PrimaryLayout';

function PrimaryLayoutRoute({
  component: Component,
  maxWidth = 'lg',
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <PrimaryLayout maxWidth={maxWidth}>
          <Component {...matchProps} />
        </PrimaryLayout>
      )}
    />
  );
}

PrimaryLayoutRoute.propTypes = {
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
};

export default PrimaryLayoutRoute;
