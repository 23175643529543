import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@material-ui/core';

function PrimaryLayout({ maxWidth, children }) {
  return (
    <Container maxWidth={maxWidth}>
      <Box py={2}>{children}</Box>
    </Container>
  );
}

PrimaryLayout.propTypes = {
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  children: PropTypes.node.isRequired,
};

export default PrimaryLayout;
