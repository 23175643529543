import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../modules/Session';
import { PrimaryLayoutRoute } from '../_layouts/PrimaryLayout';
import { LinearIndeterminate as LinearIndeterminateProgress } from '../Progress';

const Landing = lazy(() => import('../../routes/Landing'));
const Painting = lazy(() => import('../../routes/Painting'));
const NotFound = lazy(() => import('../../routes/NotFound'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LinearIndeterminateProgress />}>
        <Switch>
          <PrimaryLayoutRoute exact path={ROUTES.LANDING} component={Landing} />
          <Route path={ROUTES.PAINTING} component={Painting} />
          <PrimaryLayoutRoute component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default withAuthentication(App);
